/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-21 16:31:01
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-03 10:22:05
 */
export const columns = [
  // {
  //   title: '序号',
  //   dataIndex: 'index',
  //   key: 'index',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true,
  //   scopedSlots: { customRender: 'index'}
  // },
  {
    title: '收款单号',
    dataIndex: 'collectionCode',
    key: 'collectionCode',
    align:'center',
    ellipsis:true,
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'collectionCode'}
},
{
    title: '本次付款金额',
    dataIndex: 'amount',
    key: 'amount',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '经销商名称',
    dataIndex: 'customerName',
    key: 'customerName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '付款凭证',
    dataIndex: 'paymentVoucher',
    key: 'paymentVoucher',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: {
      customRender: 'paymentVoucher'
    }
},
{
    title: '订单编号',
    dataIndex: 'orderId',
    key: 'orderId',
    /*width:150,*/
    align:'center',
    ellipsis:true,
},
{
    title: '订单总金额',
    dataIndex: 'orderAmount',
    key: 'orderAmount',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '操作人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},

{
    title: '本次收款时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    width: 200,
    ellipsis:true
},
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   /*width:150,*/
  //   align:'center',
  //   ellipsis:true,
  //   scopedSlots: {
  //     customRender: 'action'
  //   }
  // }
]
